<template>
  <div id="export" class="scrollbar-class">
    <h3>导出记录</h3>
    <table class="table" v-loading.lock="loading">
      <thead>
        <tr>
          <td>导出内容</td>
          <td width="200">导出类型</td>
          <td width="100">数据量</td>
          <td width="150">
            <div class="icon" @click="sortFn">
              导出时间
              <div class="icon-box">
                <img
                  class="icon-bottom"
                  v-if="sort == 'desc'"
                  src="./../../../assets/img/collection/icon_bottom_hot.png"
                  alt=""
                />
                <img
                  class="icon-bottom"
                  v-if="sort == '' || sort == 'asc'"
                  src="./../../../assets/img/collection/icon_bottom.png"
                  alt=""
                />
                <img
                  class="icon-top"
                  v-if="sort == 'asc'"
                  src="./../../../assets/img/collection/icon_top_hot.png"
                  alt=""
                />
                <img
                  class="icon-top"
                  v-if="sort == '' || sort == 'desc'"
                  src="./../../../assets/img/collection/icon_top.png"
                  alt=""
                />
              </div>
            </div>
          </td>
          <td width="150">状态</td>
          <td width="100">操作</td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(el, index) in data" :key="index">
          <td>
            <span
              href="javascript:;"
              class="table-company text-ellipsis"
              :title="el.file_name"
              >{{ el.file_name }}</span
            >
          </td>
          <td>
            {{
              el.export_type_name
            }}
          </td>
          <td v-if="el.export_num != '0'">{{ el.export_num }}</td>
          <td v-else>--</td>
          <td>{{ el.ctime }}</td>
          <td>
            <div class="status" v-if="el.status == 3">
              <img
                class="status-icon"
                src="./../../../assets/img/export/export_over.png"
                alt=""
              />
              <span>已完成</span>
            </div>
            <div class="status" v-else-if="el.status == 2">
              <img
                class="status-icon"
                src="./../../../assets/img/export/export_fail.png"
                alt=""
              />
              <span>失败</span>
            </div>
            <div class="status" v-else>
              <img
                class="status-icon"
                src="./../../../assets/img/export/export_ing.png"
                alt=""
              />
              <span>处理中</span>
            </div>
          </td>
          <td>
            <div class="table-btn">
              <a
                href="javascript:;"
                class="table-btn-download"
                :class="{
                  'table-btn-download-disabled': el.status != 3,
                }"
                @click="downloadFn(el.dowanload_url, $event)"
                >下载</a
              >
              <a
                href="javascript:;"
                class="table-btn-delete"
                :class="{
                  'table-btn-delete-disabled': el.status != 2 && el.status != 3,
                }"
                @click="deleteFn(el.sign_id, el.id, $event)"
                >删除</a
              >
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <NoData title="暂无导出" v-if="!loading && data.length == 0" />
    <Page
      :config="config"
      @pageChange="pageChange"
      @limitChange="limitChange"
      v-if="firstLoad && config.total > config.limit"
    />
  </div>
</template>

<script>
  import PinganAna from "pingansec-vue-ana";
import {
  get_export_task_list,
  delete_export_job,
  get_export_file,
} from "@/api/export";
export default {
  data() {
    return {
      config: {
        total: 0,
        count: 5,
        sizes: [10, 20, 30, 40, 50],
        page: 1,
        limit: 10,
      },
      sort: "desc",
      data: [],
      loading: true,
      firstLoad: false,
      iTimer: null,
    };
  },
  created() {
    PinganAna.fire(3099)
    this.init();
  },
  beforeDestroy() {
    clearInterval(this.iTimer)
  },
  methods: {
    pageChange(val) {
      this.config.page = val
      this.init();
    },
    limitChange(val) {
      this.config.page = 1;
      this.config.limit = val;
      this.init();
    },
    sortFn() {
      if (this.sort == "desc") {
        this.sort = "asc";
      } else if (this.sort == "asc") {
        this.sort = "";
      } else {
        this.sort = "desc";
      }
      this.init();
    },
    init() {
      clearInterval(this.iTimer)
      this.loading = true;
      get_export_task_list({
        sort: this.sort,
        page: this.config.page,
        limit: this.config.limit,
      }).then((res) => {
        let { result_code, message, data } = res.data;
        if (result_code == 0) {
          this.loading = false;
          this.firstLoad = true;
          this.data = data.rows;
          this.config.total = Number(data.cnt);
          this.iTimer = setInterval(() => {
            this.init()
          }, 5000)
        } else {
          this.alert(message, "error");
        }
      });
    },
    deleteFn(sign_id, id, e) {
      if (e.target.classList.value.indexOf("table-btn-delete-disabled") > -1) {
        return false;
      }
      this.$confirm("删除后将无法重新下载且不可恢复，确认删除？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delete_export_job({
            id,
            sign_id,
          }).then((res) => {
            let { result_code, message, data } = res.data;
            if (result_code == 0) {
              this.init();
              this.alert('删除成功', "success");
            } else {
              this.alert(message, "error");
            }
          });
        })
        .catch(() => {
          this.alert("已取消删除", "info");
        });
    },
    downloadFn(url, e) {
      if (
        e.target.classList.value.indexOf("table-btn-download-disabled") > -1
      ) {
        return false;
      }
      window.open(url, '_blank');
      // get_export_file(id, sign_id);
    },
    // message提示框稍微包一下
    alert(txt, type) {
      this.$message.closeAll();
      this.$message({
        message: txt,
        type: type,
      });
    },
  },
};
</script>
<style scoped lang="scss" src="@/views/box/export/index.scss"></style>
