import http from '@/utils/http'

// 创建导出任务
export function ge_export_task(data) {
    return http({
        url: '/u-base/ac-ge_export_task',
        method: 'post',
        data
    })
}

// 列表
export function get_export_task_list(data) {
    return http({
        url: '/u-base/ac-get_export_task_list',
        method: 'get',
        params: data
    })
}

// 删除
export function delete_export_job(data) {
    return http({
        url: '/u-base/ac-delete_export_job',
        method: 'get',
        params: data
    })
}

// 下载
export function get_export_file(id, sign_id) {
    window.location.href = process.env.VUE_APP_BASE_URL + '/u-base/ac-get_export_file?id=' + id + "&sign_id=" + sign_id
}